<template>
  <button
    class="os-accordion-item-title"
    :class="{
      'os-accordion-item-title--active': isSelected,
      'os-accordion-item-title--bold': isBold
    }"
    @click="toggleItem"
  >
    <div class="row no-gutters align-center">
      <div class="col os-accordion-item-title__content">
        <slot :is-selected="isSelected" />
      </div>
      <div class="col-auto">
        <OsIcon
          name="Arrow"
          class="os-accordion-item-title__arrow text-blue-600"
          :class="isSelected ? 'rotate-180' : ''"
        />
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { AccordionItemKey } from '~/components/accordion/Menu.vue'

export default {
  name: 'AccordionItemTitle',

  props: {
    bold: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },

  setup(props) {
    const accordionItem = inject(AccordionItemKey, null)

    if (!accordionItem) {
      // eslint-disable-next-line no-console
      console.error('Accordion title must be called from inside AccordionMenu')
      throw new Error('Accordion title error')
    }

    const toggleItem = () => {
      accordionItem.toggle(accordionItem.id)
    }

    const isBold = computed(() =>
      props.bold !== undefined ? props.bold : accordionItem.bold.value
    )

    return { toggleItem, isSelected: accordionItem.isSelected, isBold }
  }
}
</script>

<style lang="scss" scoped>
.os-accordion-item-title {
  width: 100%;
  text-align: left;
  font-size: rem(18);
  color: $black--light;
  min-height: rem(56);
  padding: rem(8);
  cursor: pointer;

  @include viewport('md') {
    padding: rem(8) rem(16);
  }

  .os-accordion-item--borderless & {
    padding: 0;
    min-height: 0;
  }

  &.os-accordion-item-title--bold {
    font-family: $mb-font-text-bold;
  }

  .os-accordion-item-title__arrow {
    width: 16px;
  }
  .os-accordion-item-title__content {
    max-width: calc(100% - 16px); // icon width
  }
}
</style>
