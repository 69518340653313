<template>
  <section class="os-accordion-menu">
    <slot />
  </section>
</template>

<script lang="ts">
interface IAccordionGroup {
  model: Ref<any[]>
  toggle: (id: number | string | object) => void
  isSelected: (id: string | number | object) => boolean
  bold: Ref<boolean>
  borderless: Ref<boolean>
  singleItem: Ref<boolean>
  scrollTo: Ref<'start' | 'center' | 'end' | 'nearest'>
}
export const AccordionGroupKey: InjectionKey<IAccordionGroup> =
  Symbol.for('AccordionGroupKey')

export const AccordionItemKey: InjectionKey<
  Omit<IAccordionGroup, 'isSelected' | 'scrollToNearest'> & {
    id: any
    isSelected: Ref<boolean>
    scrollToBlock: () => void
  }
> = Symbol.for('AccordionItemKey')

const formatModelValue = (value: any) => {
  if (!value) return []

  if (Array.isArray(value)) return value

  return [value]
}

export default {
  name: 'AccordionMenu',
  props: {
    modelValue: {
      type: [String, Number, Object, Array],
      required: false,
      default: undefined
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    bold: {
      type: Boolean,
      required: false,
      default: true
    },
    borderless: {
      type: Boolean,
      required: false,
      default: false
    },
    singleItem: {
      type: Boolean,
      required: false,
      default: false
    },
    scrollTo: {
      type: String as PropType<'start' | 'center' | 'end' | 'nearest'>,
      required: false,
      default: null
    }
  },

  emits: ['update:modelValue', 'toggle-item'],

  setup(props, { emit }) {
    const isMultiple = computed(
      () => props.multiple || Array.isArray(props.modelValue)
    )

    const model = ref<any[]>([])

    const accordionModel = computed<any[]>({
      get() {
        return formatModelValue(props.modelValue || model.value)
      },
      set(val) {
        emit('update:modelValue', isMultiple.value ? val : val[0])
        model.value = val as any[]
      }
    })

    const toggleItem = (id: number | string | object) => {
      let internalValue = useCloneDeep(accordionModel.value)
      const index = internalValue.findIndex((modelId) => modelId === id)

      if (index < 0) {
        if (!isMultiple.value) {
          internalValue = [id]
        } else {
          internalValue.push(id)
        }
      } else {
        internalValue.splice(index, 1)
      }

      accordionModel.value = internalValue

      emit('toggle-item', id)
    }

    // hadles when v-model is updated in the parent component
    watch(
      () => props.modelValue,
      (val) => {
        const formattedModel = formatModelValue(val)

        if (formattedModel !== model.value) {
          model.value = formattedModel
        }
      }
    )

    const isSelected = (id: string | number | object) =>
      accordionModel.value.includes(id)

    provide(AccordionGroupKey, {
      model: accordionModel,
      toggle: toggleItem,
      isSelected,
      bold: toRef(props, 'bold'),
      borderless: toRef(props, 'borderless'),
      singleItem: toRef(props, 'singleItem'),
      scrollTo: toRef(props, 'scrollTo')
    })
  }
}
</script>

<style lang="scss" scoped></style>
