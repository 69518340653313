<template>
  <transition name="slideDown" @after-enter="scrollToBlock">
    <div v-show="isSelected" class="os-accordion-item-content">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { AccordionItemKey } from '~/components/accordion/Menu.vue'

export default {
  name: 'AccordionItemContent',

  setup() {
    const accordionItem = inject(AccordionItemKey, null)

    if (!accordionItem) {
      // eslint-disable-next-line no-console
      console.error(
        'Accordion content must be called from inside AccordionMenu'
      )
      throw new Error('Accordion content error')
    }

    onMounted(async () => {
      await nextTick()
      accordionItem.scrollToBlock()
    })

    return {
      isSelected: accordionItem.isSelected,
      scrollToBlock: accordionItem.scrollToBlock
    }
  }
}
</script>

<style lang="scss" scoped>
.os-accordion-item-content {
  padding: 0 rem(8) rem(8);
  font-size: rem(18);

  :deep(p) {
    font-size: rem(18);
  }

  @include viewport('md') {
    padding: 0 rem(16) rem(16);
  }

  .os-accordion-item--borderless & {
    padding: 0;
  }
}
</style>
