<template>
  <div
    ref="accordionItemRef"
    class="os-accordion-item"
    :class="{
      'os-accordion-item--selected': isSelected,
      'os-accordion-item--borderless': isBorderless,
      'os-accordion-item--single-item': isSingleItem
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import {
  AccordionGroupKey,
  AccordionItemKey
} from '~/components/accordion/Menu.vue'

export default {
  name: 'AccordionItem',

  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: undefined
    }
  },

  emits: ['selected'],

  setup(props, { emit }) {
    const vm = getCurrentInstance()
    const accordionGroup = inject(AccordionGroupKey, null)
    const accordionItemRef = ref()
    const itemId = computed(() => props.value || vm?.uid)

    if (!itemId.value || !accordionGroup) {
      // eslint-disable-next-line no-console
      console.error(
        'Accordion item must be called from inside a setup function and inside AccordionMenu'
      )
      throw new Error('Accordion item error')
    }

    const isSelected = computed(() => accordionGroup.isSelected(itemId.value!))

    watch(isSelected, (value) => {
      emit('selected', { value })
    })

    const scrollToBlock = () => {
      if (!isSelected.value || !accordionGroup.scrollTo.value) return

      if (accordionItemRef.value) {
        accordionItemRef.value.scrollIntoView({
          behavior: 'smooth',
          block: accordionGroup.scrollTo.value
        })
      }
    }

    provide(AccordionItemKey, {
      id: itemId.value,
      ...accordionGroup,
      isSelected,
      scrollToBlock
    })

    return {
      accordionItemRef,
      isSelected,
      isBorderless: accordionGroup.borderless,
      isSingleItem: accordionGroup.singleItem
    }
  }
}
</script>

<style lang="scss" scoped>
.os-accordion-item {
  border-bottom: solid 1px $grey--light;
  color: $black--light;

  &.os-accordion-item--single-item {
    border: 1px solid $grey--light;
    border-radius: 4px;
  }
  &.os-accordion-item--borderless {
    border: none;
  }
}
</style>
